import { Query } from "@graphql-types";
import { Header } from "@shared";
import {
  CARE_FORM_ID,
  CONTACT_US_FORM_ID,
  INFO_PACK_FORM_ID,
  METGUIDE_FORM_ID,
  VILLAGE_CONTACT_FORM_ID,
} from "@util/constants";

import { useDynamicsFormLoad } from "@util/hooks";

import { Container, Section } from "@util/standard";
import { PageProps, graphql } from "gatsby";
import React from "react";

interface Props extends PageProps {
  data: Query;
}

const AllForms = ({ data: { sanityHeader } }: Props) => {

  useDynamicsFormLoad();

  return (
    <>
      <Header data={sanityHeader} />
     <Container
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        margin="150px 0 0 0"
      >

        <h2> All Forms</h2>

        <p>Contact Form</p>
        <div data-form-block-id={CONTACT_US_FORM_ID}></div>
        <hr />
        <p>Village contact form</p>
        <div data-form-block-id={VILLAGE_CONTACT_FORM_ID}></div>

        <hr />
        <p>Car form</p>
        <div data-form-block-id={CARE_FORM_ID}></div>

        <hr />
        <p>Event Registration form</p>
        <div data-form-block-id="57bc4f41-88cf-ed11-a7c7-00224814fd52"></div>

        <hr />
        <p>Metguides form</p>
        <div data-form-block-id={METGUIDE_FORM_ID}></div>

        <hr />
        <p>Info Packs form</p>
        <div data-form-block-id={INFO_PACK_FORM_ID}></div>
      </Container>
    </>
  );
};

export default AllForms;

export function Head() {
  return (
    <>
      <meta name="robots" content="noindex" />
    </>
  );
}
export const query = graphql`
  query eventRegistrationQuery {
    sanityHeader {
      ...sanityHeader
    }
  }
`;
